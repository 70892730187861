/*
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE and NOTICE files at the root of the source
 * tree and available online at
 *
 * http://www.dspace.org/license/
 */
// Client-side scripting to support DSpace Choice Control

// IMPORTANT NOTE:
//  This code depends on a *MODIFIED* version of the
//  script.aculo.us controls v1.8.2, fixed to avoid a bug that
//  affects autocomplete in Firefox 3.  This code is included in DSpace.

// Entry points:
//  1. DSpaceSetupAutocomplete -- add autocomplete (suggest) to an input field
//
//  2.  DSpaceChoiceLookup -- create popup window with authority choices
//
//  @Author: Larry Stone  <lcs@hulmail.harvard.edu>
//  $Revision $

// -------------------- support for Autocomplete (Suggest)

// Autocomplete utility:
// Arguments:
//   formID -- ID attribute of form tag
//   args properties:
//     metadataField -- metadata field e.g. dc_contributor_author
//     inputName -- input field name for text input, or base of "Name" pair
//     authorityName -- input field name in which to set authority
//     containerID -- ID attribute of DIV to hold the menu objects
//     indicatorID -- ID attribute of element to use as a "loading" indicator
//     confidenceIndicatorID -- ID of element on which to set confidence
//     confidenceName - NAME of confidence input (not ID)
//     contextPath -- URL path prefix (i.e. webapp contextPath) for DSpace.
//     collection -- db ID of dspace collection to serve as context
//     isClosed -- true if authority value is required, false = non-auth allowed
// XXX Can't really enforce "isClosed=true" with autocomplete, user can type anything
//
// NOTE: Successful autocomplete always sets confidence to 'accepted' since
//  authority value (if any) *was* chosen interactively by a human.
function DSpaceSetupAutocomplete(formID, args)
{
    if (args.authorityName == null)
        args.authorityName = dspace_makeFieldInput(args.inputName,'_authority');

    var authInput = jQuery('#'+formID + ' input[name=\''+args.authorityName+'\']');
    var input = jQuery('#'+formID + ' input[name=\''+args.inputName+'\']');
    var confidenceButton = jQuery('#refresh_' + args.confidenceIndicatorID);
    input.parent('td').attr('style','white-space:nowrap;');
    // AJAX menu source, can add &query=TEXT
    var choiceURL = args.contextPath+"/choices/"+args.metadataField;
    var collID = args.collection == null ? -1 : args.collection;
    var onlyLocal = args.onlyLocal == null ? false : args.onlyLocal;
    var year = null;
    if(document.getElementById("dc_date_issued") != null) {
        year = document.getElementById("dc_date_issued").value.substring(0,4);
    }
    if (authInput != null)
	{
    	input.data('previousData', {authority: authInput.val(), value: input.val()});
	}
    var options = {
            minLength: 3,
            delay: 1500,
            search: function(event, ui) {
                jQuery('#'+args.indicatorID).show();
            },
            source: function( request, response ) {
                jQuery.ajax({
                    url: choiceURL,
                    dataType: "xml",
                    data: {
                        query: request.term,
                        collection: collID,
                        format: 'ul',
                        onlyLocal: onlyLocal,
                        year: year
                    },
                    success: function( data ) {
                        jQuery('#'+args.indicatorID).hide();

                        response(jQuery("li", data ).map(function() {
                            return {
                                authority : jQuery(this).attr('authority'),
                                label : jQuery('span.label',this).text(),
                                value : jQuery('span.value',this).text(),
                                extra : jQuery(this).data()
                            };
                        }));
                    }
                });
            },
            select: function(event, ui){
                input.data('previousData', ui.item);
                var authority = ui.item.authority;
                var authValue = authority;
                var extra = ui.item.extra;

                //manage extra information to copy value::authority into related field (boxed into the key map)
                jQuery.each(extra, function( key, row ) {
                    //'key' example: dc_related_example
                    //'row' example: Test::rp00001
                    var re = /(.*)::(.*)/;
                    var valsubst = '$1';
                    var authsubst = '$2';

                    var srow = String(row);
                    var valExtra = srow.replace(re, valsubst);
                    var authExtra = srow.replace(re, authsubst);
                    //calculate from the current input
                    var inputExtra = args.inputName.replace(args.metadataField, key);
                    var inputAuthorityExtra = dspace_makeFieldInput(inputExtra,'_authority');

                    if(srow.indexOf("::")==-1) {
                        authExtra = null;
                    }

                    jQuery('#'+formID + ' input[type="text"][name=\''+ inputExtra +'\']').val(valExtra);
                    // for the toggle switch:
                    if (jQuery('#'+formID + ' input[type="checkbox"][name=\''+ inputExtra +'\']').parents("label[class='switch']").length == 1) {
                        jQuery('#'+formID + ' input[type="checkbox"][name=\''+ inputExtra +'\']').prop("checked", srow !== '' && srow !== "false");
                    }
                    // for checkboxes or radio buttons
                    let selections = jQuery('#'+formID + ' input[name=\''+ inputExtra +'\']');
                    if (jQuery(selections).filter("[type='checkbox']") || jQuery(selections).filter("[type='radio']")) {
                        for (let i = 0; i < selections.length; i++) {
                            if (jQuery(selections[i]).val() === valExtra)
                                jQuery(selections[i]).prop("checked", true);
                        }
                    }

                    // for dropdown lists
                    let options = jQuery("#" + formID + " select[name='" + inputExtra + "']").children("option");
                    if (options && options.length > 0) {
                        for (let i = 0; i < options.length; i++) {
                            if (options[i].value === valExtra) {
                                if (options[0].value === "")
                                    jQuery(options[0]).prop("selected", false);
                                jQuery(options[i]).prop("selected", true);
                            }
                        }
                    }

                    if (authExtra != null) {
                        jQuery('#'+formID + ' input[name=\''+ inputAuthorityExtra + '\']').val(authExtra);

                        var confExtraName = dspace_makeFieldInput(inputExtra,'_confidence');
                        var confExtraInput = jQuery('#'+formID + ' input[name=\''+ confExtraName +'\']');
                        if (confExtraInput != null) {
                            if (authExtra != '') {
                                confExtraInput.val('accepted');
                            } else {
                                confExtraInput.val('');
                            }
                        }
                    } else {
                        jQuery('#'+formID + ' input[name=\''+ inputAuthorityExtra + '\']').val("");
                    }
                    var confIDExtraName = inputExtra + "_confidence_indicator_id";
                    // make indicator blank if no authority value
                    DSpaceUpdateConfidence(document, confIDExtraName,
                        authExtra == null || authExtra === '' ? 'blank' :'accepted');


                    // trigger change event for affected fields
                    jQuery("select[name='" + inputExtra + "']").trigger('change');
                });


                if (authInput != null)
                {
					authInput.val(authValue);
                    // update confidence input's value too if available.
                    if (args.confidenceName != null)
                    {
                        var confInput = jQuery('#'+formID + ' input[name=\''+args.confidenceName+'\']');
                        if (confInput != null)
                        {
                            if (authority != '')
                            {
                                confInput.val('accepted');
                            }
                            else
                            {
                                confInput.val('');
                            }
                        }
                    }
                }
                // make indicator blank if no authority value
                DSpaceUpdateConfidence(document, args.confidenceIndicatorID,
                    authValue == null || authValue == '' ? 'blank' :'accepted');
            },
            appendTo: jQuery(input).parent(),
            classes: {
                "ui-autocomplete": "submission-dropdown"
            }
    };

    input.autocomplete(options).change(function(){
        var lastSel = input.data('previousData');
        var newauth = '';
        var newval = input.val();
        if (authInput != null)
        {
            newauth = authInput.val();
        }
        if (newauth != lastSel.authority || newval != lastSel.value)
        {
            if (authInput != null)
            {
                authInput.val(' ');
                DSpaceUpdateConfidence(document, args.confidenceIndicatorID, 'blank');
            }
        }
    });

    // allow the image to behave like a button if the value is NOT accepted or NOT empty
    $(confidenceButton).not(".cf-accepted").not(".cf-blank").click(function() {
        input.autocomplete('search', input.val());
    });
}

// -------------------- support for Lookup Popup

// Create popup window with authority choices for value of an input field.
// This is intended to be called by onClick of a "Lookup" or "Add"  button.
function DSpaceChoiceLookup(url, field, formID, valueInput, authInput,
                            confIndicatorID, collectionID, isName, isRepeating)
{
    // fill in URL
    url += (url.indexOf('?') > -1) ? '&' : '?';
    url += 'field='+field+'&formID='+formID+'&valueInput='+valueInput+
        '&authorityInput='+authInput+'&collection='+collectionID+
        '&isName='+isName+'&isRepeating='+isRepeating+'&confIndicatorID='+confIndicatorID;

    // primary input field - for positioning popup.
    var inputFieldName = isName ? dspace_makeFieldInput(valueInput,'_last') : valueInput;
    var inputField = document.getElementById(formID).elements[inputFieldName];
    // scriptactulous magic to figure out true offset:
    var cOffset = 0;
    if (inputField != null)
        cOffset = $(inputField).cumulativeOffset();
    var width = 600;  // XXX guesses! these should be params, or configured..
    var height = 530;
    var left; var top;
    if (window.screenX == null) {
        left = window.screenLeft + cOffset.left - (width/2);
        top = window.screenTop + cOffset.top - (height/2);
    } else {
        left = window.screenX + cOffset.left - (width/2);
        top = window.screenY + cOffset.top - (height/2);
    }
    if (left < 0) left = 0;
    if (top < 0) top = 0;
    var pw = window.open(url, 'ignoreme',
        'width='+width+',height='+height+',left='+left+',top='+top+
        ',toolbar=no,menubar=no,location=no,status=no,resizable');
    if (window.focus) pw.focus();
    return false;
}

function DSpaceChoiceLookupOnlyLocal(onlyLocal, url, field, formID, valueInput, authInput,
                                     confIndicatorID, collectionID, isName, isRepeating)
{
    url += '?onlyLocal=' + onlyLocal;
    return DSpaceChoiceLookup(url, field, formID, valueInput, authInput,
        confIndicatorID, collectionID, isName, isRepeating);
}

// Run this as the Lookup page is loaded to initialize DOM objects, load choices
function DSpaceChoicesSetup(form)
{
    // find the "LEGEND" <div>
    // and save it as a bogus form element, e.g. elements['statline']
    var legend = document.getElementById('legend-results-choice-lookup');
    form.statline = legend;
    form.statline_template = legend.innerHTML;
    legend.innerHTML = "Loading ...";
    DSpaceChoicesLoad(form);
}


// populate the "select" with options from ajax request
// stash some parameters as properties of the "select" so we can add to
// the last start index to query for next set of results.
function DSpaceChoicesLoad(form)
{
    var onlyLocal = form.elements['onlyLocal'].value;
    var field = form.elements['paramField'].value;
    var value = form.elements['paramValue'].value;
    var start = form.elements['paramStart'].value;
    var limit = form.elements['paramLimit'].value;
    var formID = form.elements['paramFormID'].value;
    var collID = form.elements['paramCollection'].value;
    var isName = form.elements['paramIsName'].value == 'true';
    var isRepeating = form.elements['paramIsRepeating'].value == 'true';
    var isClosed = form.elements['paramIsClosed'].value == 'true';
    var contextPath = form.elements['contextPath'].value;
    var fail = form.elements['paramFail'].value;
    var valueInput = form.elements['paramValueInput'].value;
    var nonAuthority = "";
    if (form.elements['paramNonAuthority'] != null)
        nonAuthority = form.elements['paramNonAuthority'].value;

    // get value from form inputs in opener if not explicitly supplied
    if (value.length == 0)
    {
        var of = window.opener.document.getElementById(formID);
        if (isName)
            value = makePersonName(of.elements[dspace_makeFieldInput(valueInput,'_last')].value,
                of.elements[dspace_makeFieldInput(valueInput,'_first')].value);
        else
            value = of.elements[valueInput].value;

        // if this is a repeating input, clear the source value so that e.g.
        // clicking "Next" on a submit-describe page will not *add* the proposed
        // lookup text as a metadata value:
        if (isRepeating)
        {
            if (isName)
            {
                of.elements[dspace_makeFieldInput(valueInput,'_last')].value = null;
                of.elements[dspace_makeFieldInput(valueInput,'_first')].value = null;
            }
            else
                of.elements[valueInput].value = null;
        }
    }

    // start spinner
    var indicator = document.getElementById('lookup_indicator_id');
    if (indicator != null)
        indicator.style.display = "inline";

    let ajaxUrl=contextPath + "/choices/" + field;

    jQuery.ajax({
        method: "GET",
        url: ajaxUrl,
        dataType: "xml",
        data: {
            query: value,
            format: 'select',
            collection: collID,
            start: start,
            limit: limit,
            onlyLocal: onlyLocal
        },
        error: function() {
            console.error("Failure ...");
            window.alert(fail + " HTTP error response");
            if (indicator != null)
                indicator.style.display = "none";
        },
        success: function(transport) {
            if (transport == null) {
                alert("Your browser does not support AJAX!");
                return;
            }
            var ul = transport.documentElement;
            var err = ul.getAttributeNode('error');
            if (err != null && err.value == 'true')
                window.alert(fail + " Server indicates error in response.");
            var opts = ul.getElementsByTagName('option');

            // update range message and update 'more' button
            var oldStart = 1 * ul.getAttributeNode('start').value;
            var nextStart = oldStart + opts.length;
            var lastTotal = ul.getAttributeNode('total').value;
            var resultMore = ul.getAttributeNode('more');
            form.elements['more'].disabled = !(resultMore != null && resultMore.value == 'true');
            form.elements['paramStart'].value = nextStart;

            // clear select first
            var select = form.elements['chooser'];
            for (var i = select.length-1; i >= 0; --i)
                select.remove(i);

            // load select and look for default selection
            var selectedByValue = -1; // select by value match
            var selectedByChoices = -1;  // Choice says its selected
            for (var i = 0; i < opts.length; ++i) {
                var opt = opts.item(i);
                var olabel = "";
                for (var j = 0; j < opt.childNodes.length; ++j) {
                    var node = opt.childNodes[j];
                    if (node.nodeName == "#text")
                        olabel += node.data;
                }
                var ovalue = opt.getAttributeNode('value').value;
                var option = new Option(olabel, ovalue);
                option.authority = opt.getAttributeNode('authority').value;

                //transfer all data attributes on the option element
                option.data = jQuery(opt).data();

                select.add(option, null);
                if (value == ovalue)
                    selectedByValue = select.options.length - 1;
                if (opt.getAttributeNode('selected') != null)
                    selectedByChoices = select.options.length - 1;
            }
            // add non-authority option if needed.
            if (!isClosed)
                select.add(new Option(dspace_formatMessage(nonAuthority, value), value), null);

            var defaultSelected = -1;
            if (selectedByChoices >= 0)
                defaultSelected = selectedByChoices;
            else if (selectedByValue >= 0)
                defaultSelected = selectedByValue;
            else if (select.options.length == 1)
                defaultSelected = 0;

            // load default-selected value
            if (defaultSelected >= 0) {
                select.options[defaultSelected].defaultSelected = true;
                var so = select.options[defaultSelected];
                if (isName) {
                    form.elements['text1'].value = lastNameOf(so.value);
                    form.elements['text2'].value = firstNameOf(so.value);
                } else
                    form.elements['text1'].value = so.value;
            }

            // turn off spinner
            if (indicator != null)
                indicator.style.display = "none";

            // "results" status line
            var statLast =  nextStart + (isClosed ? 2 : 1);

            form.statline.innerHTML = dspace_formatMessage(form.statline_template,
                oldStart+1, statLast, Math.max(lastTotal, statLast), value);
        }
    });
}

// handler for change event on choice selector - load new values
function DSpaceChoicesSelectOnChange ()
{
    // "this" is the window,
    var form = document.getElementById('aspect_general_ChoiceLookupTransformer_div_lookup');
    var select = form.elements['chooser'];
    var so = select.options[select.selectedIndex];
    var isName = form.elements['paramIsName'].value == 'true';

    if (isName)
    {
        form.elements['text1'].value = lastNameOf(so.value);
        form.elements['text2'].value = firstNameOf(so.value);
    }
    else
        form.elements['text1'].value = so.value;
}

// handler for lookup popup's accept (or add) button
//  stuff values back to calling page, force an add if necessary, and close.
function DSpaceChoicesAcceptOnClick ()
{
    var select = this.form.elements['chooser'];
    var isName = this.form.elements['paramIsName'].value == 'true';
    var isRepeating = this.form.elements['paramIsRepeating'].value == 'true';
    var valueInput = this.form.elements['paramValueInput'].value;
    var authorityInput = this.form.elements['paramAuthorityInput'].value;
    var formID = this.form.elements['paramFormID'].value;
    var confIndicatorID = this.form.elements['paramConfIndicatorID'] == null?null:this.form.elements['paramConfIndicatorID'].value;
    var field = this.form.elements['paramField'].value;

    // default the authority input if not supplied.
    if (authorityInput.length == 0)
        authorityInput = dspace_makeFieldInput(valueInput,'_authority');

    // always stuff text fields back into caller's value input(s)
    if (valueInput.length > 0)
    {
        var of = window.opener.document.getElementById(formID);
        if (isName)
        {
            of.elements[dspace_makeFieldInput(valueInput,'_last')].value = this.form.elements['text1'].value;
            of.elements[dspace_makeFieldInput(valueInput,'_first')].value = this.form.elements['text2'].value;
        }
        else
            of.elements[valueInput].value = this.form.elements['text1'].value;

        if (authorityInput.length > 0 && of.elements[authorityInput] != null)
        {
            // conf input is auth input, substitute '_confidence' for '_authority'
            // if conf fieldname is  FIELD_confidence_NUMBER, then '_authority_' => '_confidence_'
            var confInput = "";

            var ci = authorityInput.lastIndexOf("_authority_");
            if (ci < 0)
                confInput = authorityInput.substring(0, authorityInput.length-10)+'_confidence';
            else
                confInput = authorityInput.substring(0, ci)+"_confidence_"+authorityInput.substring(ci+11);
            // DEBUG:
            // window.alert('Setting fields auth="'+authorityInput+'", conf="'+confInput+'"');

            var authValue = null;
            if (select.selectedIndex >= 0 && select.options[select.selectedIndex].authority != null)
            {
                authValue = select.options[select.selectedIndex].authority;
                of.elements[authorityInput].value = authValue;
            }
            if (of.elements[confInput] != null)
                of.elements[confInput].value = 'accepted';
            // make indicator blank if no authority value
            DSpaceUpdateConfidence(window.opener.document, confIndicatorID,
                authValue == null || authValue == '' ? 'blank' :'accepted');
        }

        if (select.selectedIndex >= 0 && select.options[select.selectedIndex].data != null)
        {
            var extra = select.options[select.selectedIndex].data;

            //manage extra information to copy value::authority into related field (boxed into the key map)
            jQuery.each(extra, function( key, row ) {
                //'key' example: dc_related_example
                //'row' example: Test::rp00001
                var re = /(.*)::(.*)/;
                var valsubst = '$1';
                var authsubst = '$2';

                var srow = String(row);
                var valExtra = srow.replace(re, valsubst);
                var authExtra = srow.replace(re, authsubst);
                //calculate from the current input
                var inputExtra = valueInput.replace(field, key);
                var inputAuthorityExtra = dspace_makeFieldInput(inputExtra,'_authority');

                if(srow.indexOf("::")==-1) {
                    authExtra = null;
                }

                if(of.elements[inputExtra]!=null) {
                    of.elements[inputExtra].value = valExtra;
                }

                if(of.elements[inputAuthorityExtra]!=null) {
                    if (authExtra != null) {
                        of.elements[inputAuthorityExtra].value = authExtra;

                        var confExtraName = dspace_makeFieldInput(inputExtra,'_confidence');

                        if (of.elements[confExtraName] != null) {
                            if (authExtra != '')
                            {
                                of.elements[confExtraName].value = 'accepted';
                            }
                            else
                            {
                                of.elements[confExtraName].value = '';
                            }
                        }
                    }
                    else {
                        of.elements[inputAuthorityExtra].value = '';
                    }
                }
                var confIDExtraName = inputExtra + "_confidence_indicator_id";
                // make indicator blank if no authority value
                DSpaceUpdateConfidence(window.opener.document, confIDExtraName,
                    authExtra == null || authExtra == '' ? 'blank' :'accepted');

            });

        }

        // force the submit button -- if there is an "add"
        if (isRepeating)
        {
            var add = of.elements["submit_"+valueInput+"_add"];
            if (add != null)
                add.click();
            else
                alert('Sanity check: Cannot find button named "submit_'+valueInput+'_add"');
        }
    }
    window.close();
    return false;
}

// handler for lookup popup's more button
function DSpaceChoicesMoreOnClick ()
{
    DSpaceChoicesLoad(this.form);
}

// handler for lookup popup's cancel button
function DSpaceChoicesCancelOnClick ()
{
    window.close();
    return false;
}

// -------------------- Utilities

// DSpace person-name conventions, see DCPersonName
function makePersonName(lastName, firstName)
{
    return (firstName == null || firstName.length == 0) ? lastName :
        lastName+", "+firstName;
}

// DSpace person-name conventions, see DCPersonName
function firstNameOf(personName)
{
    var comma = personName.indexOf(",");
    return (comma < 0) ? "" : stringTrim(personName.substring(comma+1));
}

// DSpace person-name conventions, see DCPersonName
function lastNameOf(personName)
{
    var comma = personName.indexOf(",");
    return stringTrim((comma < 0) ? personName : personName.substring(0, comma));
}

// replicate java String.trim()
function stringTrim(str)
{
    var start = 0;
    var end = str.length;
    for (; str.charAt(start) == ' '&& start < end; ++start) ;
    for (; end > start && str.charAt(end-1) == ' '; --end) ;
    return str.slice(start, end);
}

// format utility - replace @1@, @2@ etc with args 1, 2, 3..
// NOTE params MUST be monotonically increasing
// NOTE we can't use "{1}" like the i18n catalog because it elides them!!
// ...UNLESS maybe it were to be fixed not to when no params...
function dspace_formatMessage()
{
    var template = dspace_formatMessage.arguments[0];
    var i;
    for (i = 1; i < arguments.length; ++i)
    {
        var pattern = '@'+i+'@';
        if (template.search(pattern) >= 0)
            template = template.replace(pattern, dspace_formatMessage.arguments[i]);
    }
    return template;
}

// utility to make sub-field name of input field, e.g. _last, _first, _auth..
// if name ends with _1, _2 etc, put sub-name BEFORE the number
function dspace_makeFieldInput(name, sub)
{
    var i = name.search("_[0-9]+$");
    if (i < 0)
        return name+sub;
    else
        return name.substr(0, i)+sub+name.substr(i);
}

// update the class value of confidence-indicating element
function DSpaceUpdateConfidence(doc, confIndicatorID, newValue)
{
    // sanity checks - need valid ID and a real DOM object
    if (confIndicatorID == null || confIndicatorID == "")
        return;
    var confElt = doc.getElementById(confIndicatorID);

    if (confElt == null)
        return;



    let newClassName = '';

    // add or update CSS class with new confidence value, e.g. "cf-accepted".
    if (confElt.className == null) {
        confElt.className = "cf-" + newValue;
    }
    else if (confElt.className.startsWith('fa fa')){
        if (newValue === 'accepted' || newValue === 'blank') {
            let refreshButton = document.getElementById('refresh_' + confIndicatorID);
            refreshButton.className = refreshButton.className.replace('btn btn-primary', 'btn btn-secondary');
            jQuery("#" + 'refresh_confidence_indicator_id').show();
            jQuery('#' + confIndicatorID.replace('_confidence_indicator_id', '') + "_result").remove();
        } else if (newValue === 'rejected') {
            newClassName = 'fa fa-exclamation-triangle fa-lg colorRed iconSmall';
        } else {
            newClassName = 'fa fa-exclamation-triangle fa-lg colorYellow iconSmall';
        }
        confElt.className = newClassName;
    }
    else
    {
        var classes = confElt.className.split(" ");
        var found = false;
        for (var i = 0; i < classes.length; ++i)
        {
            if (classes[i].match('^cf-[a-zA-Z0-9]+$'))
            {
                newClassName += "cf-"+newValue+" ";
                found = true;
            }
            else
                newClassName += classes[i]+" ";
        }
        if (!found)
            newClassName += "cf-"+newValue+" ";
        confElt.className = newClassName;
    }

    jQuery("#" + confIndicatorID).show();
}

// respond to "onchanged" event on authority input field
// set confidence to 'accepted' if authority was changed by user.
function DSpaceAuthorityOnChange(self, confValueID, confIndicatorID)
{
    var confidence = 'accepted';
    if (confValueID != null && confValueID != '')
    {
        var confValueField = document.getElementById(confValueID);
        if (confValueField != null)
            confValueField.value = confidence;
    }
    DSpaceUpdateConfidence(document, confIndicatorID, confidence)
    return false;
}

// respond to click on the authority-value lock button in Edit Item Metadata:
// "button" is bound to the image input for the lock button, "this"
function DSpaceToggleAuthorityLock(button, authInputID)
{
    // sanity checks - need valid ID and a real DOM object
    if (authInputID == null || authInputID == '')
        return false;
    var authInput = document.getElementById(authInputID);
    if (authInput == null)
        return false;

    // look for is-locked or is-unlocked in class list:
    var classes = button.className.split(' ');
    var newClass = '';
    var newLocked = false;
    var found = false;
    for (var i = 0; i < classes.length; ++i)
    {
        if (classes[i] == 'is-locked')
        {
            newLocked = false;
            found = true;
        }
        else if (classes[i] == 'is-unlocked')
        {
            newLocked = true;
            found = true;
        }
        else
            newClass += classes[i]+' ';
    }
    if (!found)
        return false;
    // toggle the image, and set readability
    button.className = newClass + (newLocked ? 'is-locked' : 'is-unlocked') + ' ';
    authInput.readOnly = newLocked;
    return false;
}

function TUContributorSetupAutocomplete(formID, args) {
    if (args.authorityName == null)
        args.authorityName = dspace_makeFieldInput(args.inputName, '_authority');

    var authInput = jQuery('#' + formID + ' input[name=\'' + args.authorityName + '\']');
    var input = jQuery('#' + formID + ' input[name=\'' + args.inputName + '\']');
    var confidenceButton = jQuery('#refresh_' + args.confidenceIndicatorID);
    input.parent('td').attr('style', 'white-space:nowrap;');
    // AJAX menu source, can add &query=TEXT
    var choiceURL = args.contextPath + "/choices/" + args.metadataField;
    var collID = args.collection == null ? -1 : args.collection;
    if (authInput != null) {
        input.data('previousData', {authority: authInput.val(), value: input.val()});
    }
    var options = {
        minLength: 3,
        delay: 1500,
        response: function (event, ui) {
            let currentLanguage = jQuery("#hiddenLocaleInput").val();
            let id = event.target.id;
            let buttonText = "";
            let skipButton = false;

            if (currentLanguage.localeCompare("de") === 0) {
                if (id.includes("dc_contributor_author")) {
                    buttonText = "Neue_n externe_n Autor_in eingeben"
                } else if (id.includes("dc_contributor_editor")) {
                    buttonText = "Neue_n externe_n Herausgeber_in eingeben";
                } else if (id.includes("dc_contributor_curator")) {
                    buttonText = "Neue_n externe_n Kurator_in eingeben";
                } else if(id.includes("dc_contributor_affiliation") ||
                    id.includes("dc_contributor_authorgroup") ||
                    id.includes("dc_contributor_editoraffiliation") ||
                    id.includes("dc_contributor_curatoraffiliation")) {
                    skipButton = true;
                } else
                    buttonText = "Neue_n externen Beitragende_n eingeben";
            } else if (currentLanguage.localeCompare("en") === 0) {
                if (id.includes("dc_contributor_author")) {
                    buttonText = "Add new external author";
                } else if (id.includes("dc_contributor_editor")) {
                    buttonText = "Add new external editor";
                } else if (id.includes("dc_contributor_curator")) {
                    buttonText = "Add new external curator";
                } else if(id.includes("dc_contributor_affiliation") ||
                    id.includes("dc_contributor_authorgroup") ||
                    id.includes("dc_contributor_editoraffiliation") ||
                    id.includes("dc_contributor_curatoraffiliation")) {
                    skipButton = true;
                } else
                    buttonText = "Add new external contributor";

            }

            // Add the "new external author" object to the list of suggestions; is used in the create callback to
            // render the "new external author" button
            if (!skipButton) {
                ui.content.push({
                    html: "<button name='addExtAuthButton' class='btn btn-secondary' onclick='setAffiliationOrcidVisible(" + args.inputName + ")'>" + buttonText + "</button>",
                    isButton: true
                });
            }
        },
        search: function (event, ui) {
            jQuery('#' + args.indicatorID).show();
        },
        source: function (request, response) {
            jQuery.ajax({
                url: choiceURL,
                dataType: "xml",
                data: {
                    query: request.term,
                    collection: collID,
                    format: 'ul'
                },

                success: function (data) {
                    jQuery('#' + args.indicatorID).hide();

                    if (!args.metadataField.endsWith('affiliation'))
                        data = removeContributorsAlreadyEntered(data);

                    response(jQuery("ul", data).map(function () {
                        let results = jQuery(this).find("li");
                        let dropDownElements = [];

                        if (results.length > 0) {
                            for (let i = 0; i < results.length; i++) {
                                let result = results[i];
                                let affiliation = jQuery(result).attr('dept');
                                if (jQuery(result).attr('isTUauthor') === 'true')
                                    affiliation = '';

                                dropDownElements[i] = {
                                    authority: jQuery(result).attr('authority'),
                                    isTUauthor: jQuery(result).attr('isTUauthor'),
                                    staffStatus: jQuery(result).attr('staffStatus'),
                                    orcid: jQuery(result).attr('orcid'),
                                    label: jQuery('span.label', result).text(),
                                    value: jQuery('span.value', result).text(),
                                    affiliation: affiliation,
                                    extra: jQuery(result).data()
                                };
                            }
                        } else {
                            let noResultString = 'There are no results matching your query.';
                            let currentLanguage = jQuery("#hiddenLocaleInput").val();
                            if (currentLanguage.localeCompare("de") === 0)
                                noResultString = 'Keine Resultate, die Ihrer Anfrage entsprechen.';
                            dropDownElements[0] = {
                                noResults: noResultString
                            }
                        }
                        return dropDownElements;
                    }));
                }
            });
        },
        create: function () {
            //prevent error form jquery menuFocus
            jQuery('.ui-autocomplete').unbind('menufocus');

            jQuery(this).data('ui-autocomplete')._renderItem = function (ul, item) {

                let isTUauthor = (jQuery(item).attr("isTUauthor") === 'true');
                let externalResearcherIcon = 'class="fa fa-user externalstaff" data-original-title="External Researcher">';
                let formerResearcherIcon = 'class="fa fa-user exstaff" data-original-title="Former Researcher">';
                let internalResearcherIcon = 'class="fa fa-user staff" data-original-title="TU Researcher">';
                let orcidResearcherIcon = 'class="fa orcid-user" data-original-title="External Researcher via Orcid">';
                let iconToBeDisplayed = '<span data-toggle="tooltip" data-placement="top" title="" ';
                let authority = jQuery(item).attr("authority");
                let isFromOrcid = authority != null && authority.indexOf("::orcid::") >= 0;
                let isAffiliation = authority != null && (authority.indexOf("::ROR-ID::") >= 0 || /^ou\d{5,}$/.test(authority));
                if (isTUauthor) {
                    if (jQuery(item).attr("staffStatus") === "staff")
                        iconToBeDisplayed += internalResearcherIcon + '</span>&nbsp;';
                    else
                        iconToBeDisplayed += formerResearcherIcon + '</span>&nbsp;';
                } else if(isAffiliation) {
                    iconToBeDisplayed = "";
                } else {
                    if (isFromOrcid)
                        iconToBeDisplayed += orcidResearcherIcon + '</span>';
                    else
                        iconToBeDisplayed += externalResearcherIcon + '</span>&nbsp;';
                }


                if (item.noResults) {
                    return jQuery('<li>')
                        .append("<div>" + item.noResults + "</div>")
                        .appendTo(ul);
                } else if(item.isButton) {
                    return jQuery('<li>')
                        .append(item.html)
                        .appendTo(ul);
                } else {
                    return jQuery('<li>')
                        .append("<div>" + iconToBeDisplayed + "&nbsp;" + item.label + "</div>")
                        .appendTo(ul);
                }
            };
        },
        select: function (event, ui) {
            // check if item exists - prevents an exception when the user clicks the "Add new external author" button
            // in the autocomplete's select list
            if (!ui.item || ui.item.isButton)
                return false;

            input.data('previousData', ui.item);
            let cType = event.target.id.match(/dc_contributor_([a-z]+)(_\d+)?/)[1];
            var authority = ui.item.authority;
            var authValue = authority;
            var extra = ui.item.extra;
            let isTUauthor = (jQuery(ui.item).attr("isTUauthor") === 'true');
            let orcid = jQuery(ui.item).attr("orcid");
            let staffStatus = jQuery(ui.item).attr("staffStatus");
            let affiliation = authority != null && (authority.indexOf("::ROR-ID::") >= 0 || /^ou\d{5,}$/.test(authority));

            //manage extra information to copy value::authority into related field (boxed into the key map)
            jQuery.each(extra, function (key, row) {
                if (key.includes(cType)
                    || (cType === 'author' && key.includes('dc_contributor_affiliation'))) {

                    //'key' example: dc_related_example
                    //'row' example: Test::rp00001
                    var re = /(.*)::(.*)/;
                    var valsubst = '$1';
                    var authsubst = '$2';

                    var srow = String(row);
                    var valExtra = srow.replace(re, valsubst);
                    var authExtra = srow.replace(re, authsubst);
                    //calculate from the current input
                    var inputExtra = args.inputName.replace(args.metadataField, key);
                    var inputAuthorityExtra = dspace_makeFieldInput(inputExtra, '_authority');

                    if (srow.indexOf("::") == -1) {
                        authExtra = null;
                    }

                    jQuery('#' + formID + ' input[name=\'' + inputExtra + '\']').val(valExtra);
                    if (authExtra != null) {
                        jQuery('#' + formID + ' input[name=\'' + inputAuthorityExtra + '\']').val(authExtra);

                        var confExtraName = dspace_makeFieldInput(inputExtra, '_confidence');
                        var confExtraInput = jQuery('#' + formID + ' input[name=\'' + confExtraName + '\']');
                        if (confExtraInput != null) {
                            if (authExtra != '') {
                                confExtraInput.val('accepted');
                            } else {
                                confExtraInput.val('');
                            }
                        }
                    } else {
                        jQuery('#' + formID + ' input[name=\'' + inputAuthorityExtra + '\']').val("");
                    }
                    var confIDExtraName = inputExtra + "_confidence_indicator_id";
                    // make indicator blank if no authority value
                    DSpaceUpdateConfidence(document, confIDExtraName,
                        authExtra == null || authExtra == '' ? 'blank' : 'accepted');
                }
            });

            if (authInput != null) {
                authInput.val(authValue);
                // update confidence input's value too if available.
                if (args.confidenceName != null) {
                    var confInput = jQuery('#' + formID + ' input[name=\'' + args.confidenceName + '\']');
                    if (confInput != null) {
                        if (authority != '') {
                            confInput.val('accepted');
                        } else {
                            confInput.val('');
                        }
                    }
                }
            }
            // make indicator blank if no authority value
            DSpaceUpdateConfidence(document, args.confidenceIndicatorID,
                authValue == null || authValue == '' ? 'blank' : 'accepted');

            if (orcid && orcid.length > 0) {
                setOrcid(args.inputName, orcid);
            } else {
                setOrcid(args.inputName, "")
            }

            // set the orgUnit widget or affiliation input visible - after the user selected ...
            if (isTUauthor) { // a TU author
                setStaffStatus(args.inputName, staffStatus);
                clearAffiliation(args.inputName);
            } else {// user selected an external author
                setAffiliationOrcidVisible(args.inputName);
                setStaffStatus(args.inputName, "external");
            }
            if (staffStatus === 'external') {
                addNameResult(args.inputName, ui.item.label, 'externalstaff', input);
            } else if (affiliation){
                addNameResult(args.inputName, ui.item.label, 'affiliation', input);
                if(!ui.item.value.toLowerCase().includes('austria') && !ui.item.value.toLowerCase().includes('österreich')){
                    //check button with International co publication
                    jQuery('input[name="wb_publication_intCoWork"]').prop('checked', true);
                    if (jQuery('#wb_publication_intCoWork-info').length === 0) {
                    jQuery('label[for="wb_publication_intCoWork"]').before('<div class="alert alert-warning" id="wb_publication_intCoWork-info">' +
                        '<span lang="en">This slider was automatically checked because the affiliation country is not Austria. You can change it manually.</span>' +
                        '<span lang="de">Dieser Schieberegler wurde automatisch aktiviert, da das Zugehörigkeitsland nicht Österreich ist. Sie können dies manuell ändern.</span>' +
                        '</div>');
                    }
                    jQuery('input[name="wb_publication_intCoWork"]').change(function() {
                        var infoDiv = jQuery('#wb_publication_intCoWork-info');
                        if (infoDiv.length) {
                            infoDiv.remove();
                        }
                    });
                }
            } else {
                let tissId = jQuery("#" + args.authorityName).val().match(/will be generated::(tissId)::(\d+)/);
                if (tissId != null && tissId[1] === 'tissId') {
                    queryTissAddressBook(tissId[2], input, staffStatus);
                } else {
                    addNameResult(args.inputName, ui.item.label, staffStatus, input);
                }
            }
        }
    };

    // function triggered when the focus is removed from the input
    input.autocomplete(options).change(function () {
        var lastSel = input.data('previousData');
        var newauth = '';
        var newval = input.val();
        if (authInput != null) {
            newauth = authInput.val();
        }
        if (newauth != lastSel.authority || newval != lastSel.value) {
            if (authInput != null) {
                authInput.val(' ');
                DSpaceUpdateConfidence(document, args.confidenceIndicatorID, 'blank');
            }
        }
    });
    // allow the image to behave like a button if the value is NOT accepted or NOT empty
    $(confidenceButton).not(".cf-accepted").not(".cf-blank").click(function() {
        input.autocomplete('search', input.val());
    });
}

function addNameResult(inputName, label, staffStatus, input) {
    let tooltip = "TU member";
    if (staffStatus === 'exstaff')
        tooltip = "former TU member";
    if (staffStatus === 'externalstaff')
        tooltip = "external contributor";
    let thediv = "<div id='" + inputName + "_result' class='contributor-result'>" +
        "<span class='fa fa-user " + staffStatus + "' title='" + tooltip + "'></span>&nbsp;&nbsp;" + label + "</div>";
    if(staffStatus === "affiliation") {
        thediv = "<div id='" + inputName + "_result' class='contributor-result'>" +
            "</span>"+ label + "</div>";
    }
    if (jQuery('#' + inputName + "_result").length > 0) {
        jQuery('#' + inputName + "_result").remove();
    }
    jQuery(input).parent().append(thediv);
}

function queryTissAddressBook(tissId, input, staffStatus) {
    jQuery.getJSON({
        url: address_url + tissId,
        data: {},
        success: function (data) {
            let label = "";
            if (data.preceding_titles !== null)
                label += "<span class='contributor-title-result'>" + data.preceding_titles + "</span> ";
            label += data.last_name + ", " + data.first_name;
            if (data.postpositioned_titles !== null)
                label += " <span class='contributor-title-result'>" + data.postpositioned_titles + "</span> ";
            if (data.employee.length > 0)
                label += " [";
            for (var j = 0; j < data.employee.length; j++) {
                let emp = data.employee[j];
                if (j > 0)
                    label += ", ";
                label += emp.org_ref.code;
            }
            if (data.employee.length > 0)
                label += "]";
            if (data.orcid !== null)
                label += " <span class='fa orcid-user'></span> " + data.orcid;
            addNameResult(jQuery(input).attr("id"), label, staffStatus, input);
        },
        error: function (jqXHR, exception) {
            if (jqXHR.status == 404)
                console.debug("Person with tissId " + tissId + " seems to be former staff.");
            else if (jqXHR.status == 500)
                console.debug("Internal server error: " + exception);
            addNameResult(jQuery(input).attr("id"), jQuery(input).val(), 'exstaff', input);
        }
    });
}

function setConfidenceInputToBlank(authorityControlledInput) {
    let nameOfConfidenceInput = concatIdxName(authorityControlledInput, "confidence");
    jQuery("input[name=" + nameOfConfidenceInput + "]").val("");
}

function clearAffiliation(input) {
    let childDivs = getContributorChildren(input);
    let affiliationInput = jQuery(childDivs[0]).find("input")[0];

    // hide the affiliation input and label
    jQuery(childDivs[0]).hide();
    jQuery(affiliationInput).val("");

    let orcid_div = childDivs[1];
    if (jQuery(orcid_div).attr("name") === "hide-metadata")
        jQuery(orcid_div).hide();
}

function getContributorChildren(input) {
    let inputId;
    if (input.id != null) {
        inputId = input.id;
    } else {
        inputId = input
    }

    let parentDivOfAuthorInput = jQuery("#" + inputId).parent("div.col-md-8").parent("div.row")[0];
    return jQuery(parentDivOfAuthorInput).siblings("div");
}

function setAffiliationOrcidVisible(input) {
    let childDivs = getContributorChildren(input);
    // show the affiliation input and label
    jQuery(childDivs[0]).show();
    // show the orcid input and label
    jQuery(childDivs[1]).show();

    let status_input = jQuery(childDivs).find("select").filter(function () {
        return this.id.match(/_staffStatus/);
    });
    jQuery(status_input).val("external").change();

    var confIDExtraName = jQuery(input).attr("id") + "_confidence_indicator_id";
    // make indicator blank if no authority value
    DSpaceUpdateConfidence(document, confIDExtraName, 'blank');
}

function setOrcid(input, orcid) {
    let childDivs = getContributorChildren(input);
    let orcid_input = childDivs.find("input").filter(function () {
        return this.id.match(/_orcid/);
    });
    jQuery(orcid_input).val(orcid);
}

function setStaffStatus(input, staffStatus) {
    let childDivs = getContributorChildren(input);
    let staffStatus_input = childDivs.find("select").filter(function () {
        return this.id.match(/_staffStatus/);
    });
    jQuery(staffStatus_input).val(staffStatus).change();
}


function groupBy(array, f) {
    let groups = {};
    array.forEach(function (o) {
        var group = JSON.stringify(f(o));
        groups[group] = groups[group] || [];
        groups[group].push(o);
    });
    return Object.keys(groups).map(function (group) {
        return groups[group];
    })
}

function removeContributorsAlreadyEntered(queryData) {
    let otherContributorInputs = getOtherContributorInputs();

    let authorityValuesAlreadyEnteredAuthors = [];
    jQuery(otherContributorInputs).each(
        function () {
            authorityValuesAlreadyEnteredAuthors.push(jQuery(this).data("previousData").authority);
        }
    );

    for (let authorityValue of authorityValuesAlreadyEnteredAuthors) {
        jQuery(queryData).find('li[authority="' + authorityValue + '"]').remove();
    }

    return queryData;
}

function getOtherContributorInputs() {
    let inputReceivingContributorData = jQuery("input.ui-autocomplete-loading[name^='dc_contributor']");
    let inputName = jQuery(inputReceivingContributorData).attr("name").replace(/_\d+/, "");
    return jQuery("input[name^='" + inputName + "']").filter(contributorFilter);
}

function contributorFilter() {
    return jQuery(this).hasClass('form-control ui-autocomplete-input')
        && !jQuery(this).hasClass("ui-autocomplete-loading");
}


/**
 * This code enables fetching the contributor result line (displayed below the input field)
 * from fields that are already filled.
 * Fields that are entered or searched interactively are not covered here.
 * For this, see the code above (when selecting a "choice" from the autocomplete list).
 */
jQuery(document).ready(function() {
    let contributors = jQuery("input[id^='dc_contributor_']").not("[id*='corporate']").not("[id*='confidence']").not("[id*='authority']").not("[id*='group']").not("[id*='affiliation']").not("[id*='_result']").not("[name*='serieseditor']");
    for (var i = 0; i < contributors.length; i++) {
        let contr = contributors[i];
        let idx = jQuery(contr).attr("id").match(/\d+/);
        let confid;
        if (idx != null)
            confid = jQuery(contr).attr("id").substring(0, idx.index) + 'confidence_' + idx[0];
        else
            confid = jQuery(contr).attr("id") + "_confidence";
        if (jQuery('#' + confid).val() === 'accepted') {
            let cType = jQuery(contr).attr("id").match(/dc_contributor_([a-z]+)(_\d+)?/)[1];
            let statusId = "#tuw_" + cType + "_staffStatus";
            if (idx != null)
                statusId += "_" + idx[0];
            let staffStatus = jQuery(statusId).val();
            let label = "";
            if (staffStatus === 'external') {
                label += contr.value;
                // look for affiliation and orcid
                label += getAffiliation(cType, idx);
                label += getOrcid(cType, idx);
                // } else if (staffStatus === 'exstaff') {
                //     label += contr.value;
                //     label += getOrcid(cType, idx);
            } else {
                // this is for the "staff": make an ajax call to get the official name and the main-org-unit
                // first, get the tissID from the authority field
                let authId = "dc_contributor_" + cType + "_authority";
                if (idx != null)
                    authId += "_" + idx[0];
                let tissId = jQuery("#" + authId).val().match(/will be generated::(tissId)::(\d+)/);
                if (tissId != null && tissId[1] === 'tissId') {
                    queryTissAddressBook(tissId[2], contr, staffStatus);
                } else {
                    // console.error("Value " + jQuery("#" + authId).val() + " is not a correct TU staff.");
                    label += contr.value;
                    // look for affiliation and orcid
                    label += getAffiliation(cType, idx);
                    label += getOrcid(cType, idx);
                }
            }
            if (staffStatus === 'external')
                staffStatus = 'externalstaff';
            addNameResult(jQuery(contr).attr("id"), label, staffStatus, contr);
        } else if (jQuery('#' + confid).val() !== 'blank') {
            let currentLanguage = jQuery("#hiddenLocaleInput").val();
            let thediv = "<div id='" + jQuery(contr).attr("id") + "_result' class='contributor-result-error'>";
            if (currentLanguage.localeCompare("de") === 0) {
                if (jQuery('#' + confid).val() === 'ambiguous')
                    thediv += "Mehrere Übereinstimmungen gefunden, bitte wählen Sie aus! Drücken Sie den 'Aktualisieren' Button rechts, um die übereinstimmenden Beiträge anzuzeigen."
                else
                    thediv += "Bitte prüfen Sie den Eintrag! Drücken Sie den 'Aktualisieren' Button rechts, um die übereinstimmenden Beiträge anzuzeigen.";
            } else {
                if (jQuery('#' + confid).val() === 'ambiguous')
                    thediv += "Multiple matches found, please choose manually! Press the 'refresh' button on the right side to display matching results."
                else
                    thediv += "Please review the entry! Press the 'refresh' button on the right side to display matching results.";
            }
            thediv += "</div>";
            if (jQuery('#' + jQuery(contr).attr("id") + "_result").length > 0) {
                jQuery('#' + jQuery(contr).attr("id") + "_result").remove();
            }
            jQuery(contr).parent().append(thediv);
        }

    }
});

function getOrcid(cType, idx) {
    let orcidId = "tuw_" + cType + "_orcid";
    if (idx != null)
        orcidId += "_" + idx[0];
    if (jQuery("#" + orcidId).val() > "")
        return " <span class='fa orcid-user'></span>&nbsp;" + jQuery("#" + orcidId).val();
    return "";
}

function getAffiliation(cType, idx) {
    let affilId = "dc_contributor_";
    if (cType === 'author')
        affilId += "affiliation";
    else
        affilId += cType + "affiliation";
    if (idx != null)
        affilId += "_" + idx[0];
    if (jQuery("#" + affilId).val() > "")
        return " (" + jQuery("#" + affilId).val() + ")";
    return "";
}
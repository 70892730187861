$(document).ready(function () {
    setupTooltip();
    setupLoginPopOver();
    setupHeaderLinks();
    setupSearchQuery();
    leosHacks();
    getCurrentYear();
});

jQuery(window).resize(function () {
    let uploadFileRow = jQuery("div[name='upload-file-row']");
    let embargoDateWrapper = jQuery("div[name='embargo-date-wrapper']");
    let checksum_wrapper = jQuery("div[name='checksum_wrapper']");
    if (uploadFileRow.length > 0) {
        if (jQuery(window).width() <= 1850) {
            uploadFileRow.removeClass("form-inline");
            embargoDateWrapper.removeClass("pull-right");
            checksum_wrapper.removeClass("pull-right");
        }

        if (jQuery(window).width() >= 1850) {
            uploadFileRow.addClass("form-inline");
            embargoDateWrapper.addClass("pull-right");
            checksum_wrapper.addClass("pull-right");
        }
    }

    let removeFile = jQuery("button[name^='submit_remove']");
    let fileType = jQuery("span[id^='file_type_container']")
    if (removeFile.length > 0) {
        if (jQuery(window).width() <= 992) {
            removeFile.removeClass("pull-right");
            fileType.removeClass("pull-right")
        }
        if (jQuery(window).width() >= 992) {
            removeFile.addClass("pull-right");
            fileType.addClass("pull-right");
        }

        let access_chooser_span = jQuery("span[id^='access_chooser_pull_right']");
        jQuery.each(access_chooser_span, function (index, value) {
                if (jQuery(value).attr("data-isOriginal") === "true") {
                    if (jQuery(window).width() <= 1200) {
                        access_chooser_span.removeClass("pull-right");
                    }
                    if (jQuery(window).width() >= 1200) {
                        access_chooser_span.addClass("pull-right");
                    }
                }
            }
        );
    }
});

function setupSearchQuery() {
    jQuery('input#searchquery').keyup(function(event) {
        if (event.which === 13) {
            event.preventDefault();
            $('form#searchprocessor').submit();
        }
    });
}

function leosHacks() {
    /* mobile menu sidebar extensions */
    jQuery('.navbar-header button.navbar-toggle').on('click', function () {
        let left = (jQuery('.sidebar').position().left == 0) ? -300 : 0;
        let headerLeft = (jQuery('.sidebar').position().left == 0) ? -250 : -15;
        if (jQuery(window).width() < 420) {
            let width = (jQuery('.sidebar').width() == 260) ? '100%' : 300;
            jQuery('.sidebar').animate({width: width, left: left});
        } else {
            jQuery('.sidebar').animate({left: left});
        }
        jQuery('.navbar-header').animate({'margin-left': headerLeft});
    });

    var width = jQuery(window).width();

    jQuery(window).resize(function () {
        if (jQuery(window).width() != width) {
            width = jQuery(window).width();
            jQuery('.navbar-header').css({'margin-left': ""});
            jQuery('.sidebar').css({width: "", left: ""});
            jQuery('#navbar').removeClass('in').addClass('collapse');
        }
    });
    /* safari bekommt kein focus */
    if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent))
        jQuery("#loginArrowButton").click(function () {
            jQuery(this).focus()
        });
}

function setupTooltip() {
    jQuery('[data-toggle="tooltip"]').tooltip();
}

function setupHeaderLinks() {
    jQuery("#headerLinkList > li > a").click(function () {
        jQuery("#headerLinkList  > li > a > u").removeClass("visibleUnderline");
        jQuery(this).find("u").addClass("visibleUnderline");
    });
}

function changeLanguage() {
    let userSelectedLanguage = "en";
    // class active = toggle shows de, NO class active = toggle shows en
    if (jQuery("#languageToggle").hasClass("active"))
        userSelectedLanguage = "de";

    // language changed in submission?
    if (submitFormPresent()) {
        if (startSubmitFormPresent()) {
            jQuery("input[name=locale]").val(userSelectedLanguage);
            getSubmitForm().submit();
        } else {
            jQuery("input[name=locale]").val(userSelectedLanguage);
            let current = jQuery("input.submitProgressButtonCurrent.btn");
            current.prop("disabled", false);
            current.click();
        }
    } else {
        // language toggle was clicked on any other page than a submission page
        reloadPageWithRequestParameter("locale", userSelectedLanguage);
    }
}

function submitFormPresent() {
    return getSubmitForm().length > 0
}


function getSubmitForm() {
    return jQuery("form[action*='submit'][method!='get']");
}

function startSubmitFormPresent() {
    return jQuery("input[id='submit-method']").length > 0
}

function reloadPageWithRequestParameter(parameterName, value) {
    parameterName = encodeURIComponent(parameterName);
    value = encodeURIComponent(value);

    // kvp looks like ['key1=value1', 'key2=value2', ...]
    let kvp = document.location.search.substr(1).split('&');
    //remove empty Strings
    kvp = kvp.filter(function (e) {
        return e
    });

    let i = 0;
    for (; i < kvp.length; i++) {
        if (kvp[i].startsWith(parameterName + '=')) {
            let pair = kvp[i].split('=');
            pair[1] = value;
            kvp[i] = pair.join('=');
            break;
        }
    }

    if (i >= kvp.length) {
        kvp[kvp.length] = [parameterName, value].join('=');
    }
    // reload page with new params
    document.location.search = kvp.join('&');
}

function setupLoginPopOver() {
    let options = {
        container: 'body',
        placement: 'bottom',
        html: true,
        template: '<div class="popover loginPopOver" role="tooltip"><div class="arrow"></div><h3 class="popover-title"></h3><div class="popover-content"><div class="data-content"></div></div></div>'
    };
    jQuery('[data-toggle="popover"]').popover(options);
}

function getCurrentYear() {
    jQuery("#year").append(new Date().getFullYear());
}
function UnCryptMailto( s )
{
    var n = 0;
    var r = "";
    for( var i = 0; i < s.length; i++)
    {
        n = s.charCodeAt( i );
        if( n >= 8364 )
        {
            n = 128;
        }
        r += String.fromCharCode( n - 1 );
    }
    return r;
}


